<template>
  <div class="row child-component">
    <div class="col-lg-7 col-md-8 col-sm-8 col-xs-6 mx-auto">
      <div class="d-block row col-lg-8 mx-auto">
        <div class="fieldborder mb-n2">
          <b-form-group label="Did the vendor indicate that they would like to be setup for ACH payments on the external vendor form?"
            class="timeonly-text">
            <b-form-radio-group style="margin-left:14px;" v-model="ProdExpVendor.ACH" id="time-only" name="time-only">
              <b-form-radio value="YES">Yes</b-form-radio>
              <b-form-radio value="NO">No</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="fieldborder mb-n2" v-if="ProdExpVendor.ACH == 'YES'">
          <b-form-group label="Did the vendor provide a bank letter, invoice or voided check confirming their account information?"
            class="timeonly-text">
            <b-form-radio-group style="margin-left:14px;" v-model="ProdExpVendor.HasBankLetterInvoice" id="HasBankLetterInvoice" name="HasBankLetterInvoice">
              <b-form-radio value="YES">Yes</b-form-radio>
              <b-form-radio value="NO">No</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div v-if="ProdExpVendor.ACH == 'YES' && ProdExpVendor.HasBankLetterInvoice == 'YES'">
          <div class="fieldborder pt-2">
              <label>Bank Name</label>
              <b-form-input @focus="ResetDirty('BankName')" @blur="SetDirty('BankName')" autocomplete="off"
                  id="bank-name" class="omniipclass" v-model="ProdExpVendor.BankName" placeholder="Enter Bank Name"
                  :maxlength="99">
              </b-form-input>
              <div class="error-message-format"
                  v-if="$v.ProdExpVendor.BankName.$dirty && !$v.ProdExpVendor.BankName.required">
                  Required field
              </div>
          </div>
          <div class="fieldborder pt-2">
              <label>BANK COUNTRY</label>
              <OmniSelect @focus="ResetDirty('BankCountry')" @blur="SetDirty('BankCountry')"
                  class="pt-0 mb-0 omniipclass omniindexclass" placeholder="Select Country" name="BankCountry"
                  :showLabel="false" label="BankCountry" v-model="ProdExpVendor.BankCountry" :options="CountryList">
              </OmniSelect>
              <div class="error-message-format"
                  v-if="$v.ProdExpVendor.BankCountry.$dirty && !$v.ProdExpVendor.BankCountry.required">
                  Required field
              </div>
          </div>
          <div class="fieldborder pt-2">
              <label>BANK ADDRESS LINE 1 </label>
              <b-form-input @focus="ResetDirty('BankAddressLine1')" @blur="SetDirty('BankAddressLine1')"
                  autocomplete="off" id="bankaddressline1" class="omniipclass"
                  v-model="ProdExpVendor.BankAddressLine1" placeholder="Enter Bank Address Line1"
                  :maxlength="16"></b-form-input>
              <div class="error-message-format"
                  v-if="$v.ProdExpVendor.BankAddressLine1.$dirty && !$v.ProdExpVendor.BankAddressLine1.required">
                  Required field
              </div>
          </div>
          <div class="fieldborder pt-2">
              <label>BANK ADDRESS LINE 2 </label>
              <b-form-input @focus="ResetDirty('BankAddressLine2')" @blur="SetDirty('BankAddressLine2')"
                  autocomplete="off" id="bankaddressline2" class="omniipclass"
                  v-model="ProdExpVendor.BankAddressLine2" placeholder="Enter Bank Address Line2"
                  :maxlength="10"></b-form-input>
          </div>
          <div class="fieldborder pt-2" v-if="IsCountryUSA">
              <label for="city" class="lbltxt">BANK CITY</label>
              <b-form-input @focus="ResetDirty('BankCity')" @blur="SetDirty('BankCity')" autocomplete="off"
                  id="billingCity" class="omniipclass" v-model="ProdExpVendor.BankCity"
                  placeholder="Enter City" :maxlength="19"></b-form-input>
              <div class="error-message-format"
                  v-if="$v.ProdExpVendor.BankCity.$dirty && !$v.ProdExpVendor.BankCity.required">
                  Required field
              </div>
          </div>
          <div class="row fieldborder pt-2" v-if="IsCountryUSA">
              <div class="col-md-8">
                  <label for="BankState" class="lbltxt">BANK State</label>
                  <OmniSelect @blur="SetDirty('BankState')" @focus="ResetDirty('BankState')"
                      class="pt-0 omniindexclass omniindexclass" placeholder="Select State"
                      name="BankState" :showLabel="false" :showSearch="true"
                      v-model="ProdExpVendor.BankState" :options="StateList">
                  </OmniSelect>
                  <div class="error-message-format"
                      v-if="$v.ProdExpVendor.BankState.$dirty && !$v.ProdExpVendor.BankState.required">
                      Required field
                  </div>
              </div>
              <div class="col-md-4">
                  <label v-if="IsCountryUSA" for="BankZipCode" class="lbltxt">ZIP CODE</label>

                  <b-form-input autocomplete="off" id="BankZipCode" class="" :minlength="5"
                  v-if="IsCountryUSA" :maxlength="5" v-bind:class="{
                          redtextbox: (validationtextbox || !$v.ProdExpVendor.BankZipCode.numeric) && $v.ProdExpVendor.BankZipCode.$dirty,
                          notredtextbox: !validationtextbox && $v.ProdExpVendor.BankZipCode.numeric,
                      }" @blur="SetDirty('BankZipCode')" @focus="ResetDirty('BankZipCode')"
                      @keyup="ValidateZipCode()" v-model="ProdExpVendor.BankZipCode"
                      placeholder="Enter Zip Code"></b-form-input>
                  <div v-show="validationtextbox && $v.ProdExpVendor.BankZipCode.$dirty" style="width:135px;"
                      class="error-message-format">
                      Input must be 5 characters
                  </div>
                  <div v-show="!$v.ProdExpVendor.BankZipCode.numeric && $v.ProdExpVendor.BankZipCode.$dirty"
                      style="width:135px;" class="error-message-format">
                      This field can only contain numerical values
                  </div>
              </div>
          </div>
          <div class="fieldborder pt-2">
            <label>Bank Account name</label>
            <b-form-input autocomplete="off" id="AccountName" class="omniipclass" v-model="ProdExpVendor.BankAccountName" placeholder="Enter Account name"></b-form-input>
          </div>
          <div class="fieldborder pt-2">
            <label>Bank Account number</label>
          <the-mask type="text" :tokens="{ '#': { pattern: /\d/ } }" :mask="['##', '#################']"
            style="margin-top:1px;" class="form-control" @focus="ResetDirty(ProdExpVendor.BankAccountNumber)"
            @blur="SetDirty(ProdExpVendor.BankAccountNumber)" placeholder="Enter Account Number" autocomplete="off" id="BankAccountNumber" v-model="ProdExpVendor.BankAccountNumber"></the-mask>
          </div>
          <div class="fieldborder pt-2">
            <label>Routing number</label>
          <the-mask type="text" :tokens="{ '#': { pattern: /\d/ } }" :mask="['##', '#########']"
            style="margin-top:1px;" class="form-control" @focus="ResetDirty(ProdExpVendor.BankRoutingNumber)"
            @blur="SetDirty(ProdExpVendor.BankRoutingNumber)" placeholder="Enter Routing Number" autocomplete="off" id="BankRoutingNumber" v-model="ProdExpVendor.BankRoutingNumber"></the-mask>
          </div>
        </div>
        <div>
          <div class="fieldborder pt-2" v-if="ProdExpVendor.ACH == 'YES'">
            <label>Remittance Advice Email</label>
            <b-form-input autocomplete="off" id="entitynameperagreement" class="omniipclass"
              v-model="ProdExpVendor.RemittanceAdviceEmail" placeholder="Enter Remittance Advice Email"
              :maxlength="99"></b-form-input>
            <div class="error-message" v-if="!$v.ProdExpVendor.RemittanceAdviceEmail.email">
              Please enter a valid email address (eg. test@FinSys.com)
            </div>
          </div>
          <div class="fieldborder pt-2" v-if="ProdExpVendor.ACH == 'YES'">
            <label for="axlocatoin" class="lbltxt">Please attach bank letter, invoice, or voided check.</label>
            <vue-dropzone v-on:vdropzone-mounted="mountedmyVueDropzone" :duplicateCheck="true" ref="myVueDropzone" id="dropzone" :useCustomSlot="true"
              class="customdropzone" :include-styling="false" :options="dropzoneOptions"
              v-on:vdropzone-file-added="UploadedtoDropzone" v-on:vdropzone-removed-file="RemovedFromDropzone"
              v-modal="test">
              <div class="dropzone-custom-content">
                <p class="Drag-and-drop-file-h">
                  <b-img style="
                        margin-top: -7px !important;
                        font-family: 'Open Sans';
                      " />
                  Drag and drop file here or
                  <span class="draganddropspan"> browse </span>

                </p>
              </div>
            </vue-dropzone>
          </div>

          <!-- <div class="fieldborder" style="margin-top: -22px;">
           <label>COUNTRY </label>
                 <OmniSelect
                    placeholder="Select Country"
                    name="Media"
                    :showLabel="false"
                    label="Select Country"
                    :options="InterAgencyCountryList"
                    v-model="ProdExpVendor.Country"
                  />
          </div>
          <div class="fieldborder" style="margin-top: -22px;">
           <label>BANK ADDRESS LINE 1 </label>
                 <b-form-input
                autocomplete="off"
                id="entitynameperagreement"
                class="omniipclass"
                v-model="ProdExpVendor.AdrsLine1"
                placeholder="Enter  Bank Address Line1"
                :maxlength="99"
              ></b-form-input>
          </div>
          <div class="fieldborder" style="margin-top: -22px;">
           <label>BANK ADDRESS LINE 2 </label>
                 <b-form-input
                autocomplete="off"
                id="entitynameperagreement"
                class="omniipclass"
                v-model="ProdExpVendor.AdrsLine2"
                placeholder="Enter Bank Address Line2"
                :maxlength="99"
              ></b-form-input>
          </div>
          <div class="fieldborder" style="margin-top: -22px;">
           <label>ACCOUNT NAME </label>
                 <b-form-input
                autocomplete="off"
                id="entitynameperagreement"
                class="omniipclass"
                v-model="ProdExpVendor.AccountName"
                placeholder="Enter Account Name"
                :maxlength="99"
              ></b-form-input>
          </div>
          <div class="fieldborder" style="margin-top: -22px;">
           <label>BANK NUMBER </label>
                 <b-form-input
                autocomplete="off"
                id="entitynameperagreement"
                class="omniipclass"
                v-model="ProdExpVendor.BankNumber"
                placeholder="Enter Bank Number"
                :maxlength="99"
              ></b-form-input>
          </div>
          <div class="fieldborder" style="margin-top: -22px;">
           <label>Routing Number (US Only) </label>
                 <b-form-input
                autocomplete="off"
                id="entitynameperagreement"
                class="omniipclass"
                v-model="ProdExpVendor.Routing"
                placeholder="Enter Routing Number (US Only)"
                :maxlength="99"
              ></b-form-input>
          </div>
          <div class="fieldborder" style="margin-top: -22px;">
           <label>Sort/Swift (Non US) </label>
                 <b-form-input
                autocomplete="off"
                id="entitynameperagreement"
                class="omniipclass"
                v-model="ProdExpVendor.SortSwift"
                placeholder="Enter Sort/Swift (Non US)"
                :maxlength="99"
              ></b-form-input>
          </div>
          <div class="fieldborder" style="margin-top: -22px;">
           <label>IBAN (Non US)</label>
                 <b-form-input
                autocomplete="off"
                id="entitynameperagreement"
                class="omniipclass"
                v-model="ProdExpVendor.IBAN"
                placeholder="Enter IBAN (Non US)"
                :maxlength="99"
              ></b-form-input>
          </div> -->


        </div>
      </div>

    </div>
    <div class="form-group pt-60 text-right  footer-buttons" style="padding-right:96px !important;">
      <button class="btn btn-link text-uppercase" @click="Cancel()">
        CANCEL
      </button>
      <button class="text-uppercase btn custom-button btn-primary" @click="Previous()">
        BACK
      </button>
      <button class="text-uppercase btn custom-button btn-primary" @click="Next()" :disabled="this.$v.$invalid">
        NEXT
      </button>
    </div>
    <b-modal no-enforce-focus no-close-on-backdrop size="xl" v-model="ACHBankingInfo" class="Title-Text" hide-footer
      title="Note" style="z-index:1;" @close="onClose">
      <p class="ACHcontent">
        The vendor will be setup for check payments using the address on their W-9 or invoice.
      </p>
      <br />
      <div class="text-right">
        <button class="btn btn-link text-uppercase" @click="CancelACHBankingInfo()">
          OK
        </button>
      </div>
    </b-modal>
  </div>
</template>


<style scoped>
.ACHcontent {
  height: 19px;
  margin: 6px 0 0;
  font-family: 'Open Sans' !important;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: normal;
  color: #4d5161;
}

.error-message {
  padding-top: 10px;
  padding-bottom: 5px;
  height: 14px !important;
  font-size: 12px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #df5678 !important;
}

.floating-label-input {
  margin-top: 0px !important;
}

.omniipclass {
  /* width: 415px !important; */
  height: 35px !important;
  border: #e9eef0 !important;
  /* style="width:415px; height:35px;" */
}

.Text {
  width: 250px;
  height: 196px;
  font-family: "Open Sans";
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.Text .text-style-1 {
  font-weight: 600;
}

.customdropzone {
  height: 44px !important;
  border-radius: 8px !important;
  border: dashed 2px #e9eef0 !important;
  background-color: #ffffff !important;
  cursor: pointer !important;
}

.Drag-and-drop-file-h {
  margin-left: 18px !important;
  margin-top: 14px !important;
  width: 387px !important;
  height: 10px !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 0.71 !important;
  letter-spacing: normal !important;
  /* color: #a2a9ad !important; */
}

.draganddropspan {
  color: #00a1d2;
}
</style>

<script>
import { mapGetters } from "vuex";
import service from "../../../services/Dropdowns";
import { required, requiredIf, helpers,minLength ,numeric } from "vuelidate/lib/validators";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { TheMask } from 'vue-the-mask';
const EmailRegEx = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[x01-x08x0bx0cx0e-x1fx21x23-x5bx5d-x7f]|[x01-x09x0bx0cx0e-x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9]{2,}(?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[x01-x08x0bx0cx0e-x1fx21-x5ax53-x7f]|\\[x01-x09x0bx0cx0e-\x7f])+)\])$/
const EmailWithoutCase = helpers.regex('EmailWithoutCase', EmailRegEx)
export default {
  validations: {
    ProdExpVendor: {

      ACH: { required },

      RemittanceAdviceEmail: {
        required: requiredIf(function (model) {
          return model.ACH == "YES";
        }),
        email: EmailWithoutCase
      },
      WireInfoSheets: {
        minLength: minLength(1),
        required: requiredIf(function (model) {
          return model.ACH == "YES";
        })
      },
      HasBankLetterInvoice: {
        required: requiredIf(function (model) {
          return model.ACH == "YES";
        })
      },
      BankAccountName: {
        required: requiredIf(function (model) {
          return model.ACH == "YES" && model.HasBankLetterInvoice == "YES";
        })
      },
      BankAccountNumber: {
        required: requiredIf(function (model) {
          return model.ACH == "YES" && model.HasBankLetterInvoice == "YES";
        }),
        numeric,
      },
      BankRoutingNumber: {
        required: requiredIf(function (model) {
          return model.ACH == "YES" && model.HasBankLetterInvoice == "YES";
        }),
        numeric,
      },
      BankName: {
        required: requiredIf(function (model) {
          return model.ACH == "YES" && model.HasBankLetterInvoice == "YES";
        })
      },
      BankCountry: {
        required: requiredIf(function () {
          return this.ProdExpVendor.ACH == "YES" && this.ProdExpVendor.HasBankLetterInvoice == "YES";
        })
      },
      BankAddressLine1: {
        required: requiredIf(function () {
          return this.ProdExpVendor.ACH == "YES" && this.ProdExpVendor.HasBankLetterInvoice == "YES";
        })
      },
      BankCity: {
          required: requiredIf(function (model) {
              return model.BankCountry == this.CountryUnitedStatesValue && this.ProdExpVendor.ACH == "YES" && this.ProdExpVendor.HasBankLetterInvoice == "YES";
          }),
      },
      BankState: {
          required: requiredIf(function (model) {
              return model.BankCountry == this.CountryUnitedStatesValue && this.ProdExpVendor.ACH == "YES" && this.ProdExpVendor.HasBankLetterInvoice == "YES";
          }),
      },
      BankZipCode: {
          required: requiredIf(function (model) {
              return model.BankCountry == this.CountryUnitedStatesValue && this.ProdExpVendor.ACH == "YES" && this.ProdExpVendor.HasBankLetterInvoice == "YES";
          }),
          numeric,
      },
    }
  },
  data() {
    return {
      CountryUnitedStatesValue: "USA - United States",
      ddl: 0,
      ACHBankingInfo: false,
      InterAgencyCountryList: [],
      FilesUploaded: false,
      dropzoneOptions: {
        closeFilterIcon: require("@/assets/Icons/add-client.svg"),
        previewTemplate: this.template(),
        url: `${process.env.VUE_APP_API_ROOT}api/v1/ProfileUnlock/test`,
        uploadMultiple: true,
        maxFiles: 5,
        minFiles: 1,
        maxFilesize: 10,
        addRemoveLinks: false,
        parallelUploads: 5,
        createImageThumbnails: false,
        autoProcessQueue: false,
        init: function () {
          this.on("addedfile", function (file) {
            if (this.files.length > 5) {
              this.removeFile(file);
              window.alert('Maximum File Upload limit is 5!');
            }
          });
        },
      },
      CountryList: [],
      StateList: [],
      validationtextbox: false,
    }
  },
  components: {
    vueDropzone: vue2Dropzone, TheMask
  },
  props: {
        id: {
            type: Number,
            required: false,
        },
        isDraft: {
            type: Boolean,
            required: false
        }
  },
  mounted() {
      this.LoadDropdownValues();
  },
  computed: {
    ...mapGetters('prodexpvendor', {
      ProdExpVendor: 'ProdExpVendor',
    }),
    IsEdit() {
      return this.id && !isNaN(this.id) && parseInt(this.id) > 0 ? true : false;
    },
    IsCountryUSA() {
        return this.ProdExpVendor.BankCountry != null ?
            this.ProdExpVendor.BankCountry == this.CountryUnitedStatesValue :
            null;
    },
  },
  methods: {
      SetDirty(propertyName) {
          this.$v.ProdExpVendor[propertyName].$touch();

      },
      ResetDirty(propertyName) {
          this.$v.ProdExpVendor[propertyName].$reset();
      },
    Cancel() {
      this.$router.push("/VendorRequests");
    },
    template: function () {
      return `<div class="dz-preview dz-file-preview" style='background-color:#ffffff !important;display:flex;'>

                <div class="dz-details" style='margin-right:79px !important;'>
                    <div class="dz-size" style="display:none;" ><span data-dz-size></span></div>
                    <div class="FileforNewClientxls-Copy" style="margin-left:20px;width:280px;cursor:default !important;" ><span style='word-break:break-all !important;font-size:14px !important;font-weight:400;font-stretch:normal;font-style: normal;line-height: 0.71;color: #00a1d2 !important; font-family:"Open Sans" !important;' data-dz-name></span></div>
                </div>
                <a>
                 <img data-dz-remove=""  style='' src='/icons/fileremove.svg'  @click="DeleteServerFile()" ></img>
                </a>
            </div>`;
    },
    mountedmyVueDropzone() {
      if (this.ProdExpVendor.ID > 0 && this.FilesUploaded == false) {
        this.ManuallyUploadFiles(this.ProdExpVendor.WireInfoSheets);
        this.$set(this.ProdExpVendor, "WireInfoSheets", this.GetFilesObject());
      }
    },

    UploadedtoDropzone() {
      this.ddl = this.$refs.myVueDropzone.dropzone.files.length;
      this.$set(this.ProdExpVendor, "WireInfoSheets", this.GetFilesObject());
    },
    RemovedFromDropzone() {
      this.ddl = this.$refs.myVueDropzone.dropzone.files.length;
      this.$set(this.ProdExpVendor, "WireInfoSheets", this.GetFilesObject());
    },
     ManuallyUploadFiles(files) {
      if (this.$refs.myVueDropzone != null) {
        if (files != null && files.length > 0 && this.FilesUploaded == false) {
          files.forEach((fileUploaded) => {
            let file = {
              size: fileUploaded.Length,
              name: fileUploaded.Name,
              type: fileUploaded.Extension,
            };
            var url = fileUploaded.FullName;
            this.$refs.myVueDropzone.manuallyAddFile(file, url);
          });
        }
        this.FilesUploaded = true;
      }
    },

    GetFilesObject() {
      let files = [];
      if (this.$refs.myVueDropzone.dropzone.files && this.$refs.myVueDropzone.dropzone.files.length > 0) {
        let filesExists = this.$refs.myVueDropzone.dropzone.files;
        for (let i = 0; i < filesExists.length; i++) {
          files.push({
            name: filesExists[i].name, file: filesExists[i]
          });
        }
        return files;
      } else {
        return files;
      }
    },

    // UploadedtoDropzone() {
    //      this.ddl = this.$refs.myVueDropzone.dropzone.files.length;
    //     //this.$set(this.ProdExpVendor, "SetupInfo", this.GetFilesObject());
    // },
    // RemovedFromDropzone() {
    //   this.ddl = this.$refs.myVueDropzone.dropzone.files.length;
    //     //this.$set(this.ProdExpVendor, "SetupInfo", this.GetFilesObject());
    // },

    CancelACHBankingInfo() {
      this.ACHBankingInfo = false;
    },
    ValidateZipCode() {
        if (
            this.BankZipCode && this.BankZipCode.length != 0 &&
            this.BankZipCode.length <= 5
        ) {
            this.validationtextbox = true;
        } else {
            this.validationtextbox = false;
        }
    },
    LoadDropdownValues() {
        this.LoadStates();
        service
            .getEntityRecords("Markets")
            .then((res) => {
                if (res.data && res.data.length > 0) {
                    this.CountryList = res.data.sort((a, b) =>
                        a.label < b.label ? -1 : a.label > b.label ? 1 : 0
                    );
                    let indexUSA = this.CountryList.findIndex(
                        (i) => i.label == this.CountryUnitedStatesValue
                    );
                    let indexCanada = this.CountryList.findIndex(
                        (i) => i.label == "CAN - Canada"
                    );
                    if (indexCanada > -1)
                        this.CountryList.unshift(
                            this.CountryList.splice(indexCanada, 1)[0]
                        );
                    if (indexUSA > -1)
                        this.CountryList.unshift(this.CountryList.splice(indexUSA, 1)[0]);
                    this.CountryList.forEach(element => { element.value = element.label;});
                }
            })
            .catch((err) => {
                alert(err);
            });
    },
    async LoadStates() {
        service
            .getEntityRecords("States")
            .then((res) => {
                this.StateList = res.data;
                this.StateList.forEach(element => { element.value = element.label;});
            })
            .catch((err) => {
                alert(err);
            });
    },

    Next() {
      this.$parent.$emit("stepChanged", this.$route.meta.tabIndex);
      if (this.id) {
        this.$router.push({
          name: "Production Expense Vendor Services",
          params: {
            id: this.id,
            isDraft: this.isDraft
          }
        });
      } else {
        this.$router.push({
          name: "Production Expense Vendor Services"
        });
      }
    },
    Previous() {
      if (this.id) {
        this.$router.push({
          name: "Production Expense Vendor Setup Info",
          params: {
            id: this.id,
            isDraft: this.isDraft
          }
        });
      } else {
        this.$router.push({
          name: "Production Expense Vendor Setup Info"
        });
      }
    },

    forminvalid() {
      if (this.ProdExpVendor.ACH == "YES") {
        if (this.ddl > 0) {
          return false;
        } else {
          return true;
        }
      }
      else {
        return false;
      }

    },

  },

  watch: {
    "ProdExpVendor.IsEditDataLoaded": function (val) {
      if (this.IsEdit == true && val == true) {
        this.ManuallyUploadFiles(this.ProdExpVendor.WireInfoSheets);
      }
    },
    "ProdExpVendor.ACH": function (val) {
      if(val) {
        this.ProdExpVendor.HasBankLetterInvoice = '';
      }
      if(val == 'NO') {
        this.ACHBankingInfo = true;
      }
      else {
        this.ProdExpVendor.RemittanceAdviceEmail = '';
      }
    },
    "ProdExpVendor.HasBankLetterInvoice": function (val) {
      if (val == 'YES' || val == 'NO') {
        this.ProdExpVendor.BankName = '';
        this.ProdExpVendor.BankCountry = '';
        this.ProdExpVendor.BankAddressLine1 = '';
        this.ProdExpVendor.BankAddressLine2 = '';
        this.ProdExpVendor.BankCity = '';
        this.ProdExpVendor.BankState = '';
        this.ProdExpVendor.BankZipCode = '';
        this.ProdExpVendor.BankAccountName = '';
        this.ProdExpVendor.BankAccountNumber = '';
        this.ProdExpVendor.BankRoutingNumber = '';
      }
      if(val == 'NO'){
        this.ACHBankingInfo = true;
      }
    },
    "ProdExpVendor.BankCountry": function (val) {
      if (val) {
        this.ProdExpVendor.BankCity = '';
        this.ProdExpVendor.BankState = '';
        this.ProdExpVendor.BankZipCode = '';
      }
      if(val == 'NO'){
        this.ACHBankingInfo = true;
      }
    },
  },
  activated() {
    this.$parent.$emit("stepInitiated", this.$route.meta.tabIndex);
  },
}
</script>